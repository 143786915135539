body {
    background-color: #F5F5F5;
    color: #333333;
    font-family: 'br', sans-serif;
    margin: 0;
    padding: 0;
}
  
header {
    background-color: #111111;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 12vh;
}
  
h1 {
    color: #F5F5F5;
    font-size: 50px;
    font-weight: bold;
    margin: 0;
    margin-left: 2%;
}

.main-homepage-container {
  height: 100vh;
  overflow: hidden;
}


main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 12vh); /* Subtract header height from viewport height */
    box-sizing: border-box;
}
  
.content {
    align-items: center;
    display: flex;
    text-align: center;
    width: 80%;
    max-width: 80%;
    margin: 0 auto;
}
  
.paragraph {
    width: 100%;
    height: 100%;
    justify-content: center;
}
  
p {
    font-size: 2em;
    margin-top: 0px;
    margin-left: 10%;
    text-align: left;
}
  
.profile {
    display: inline-block;
}
  
img {
    border-radius: 50%;
    width: 100%;
    max-width: 400px;
    height: auto;
    margin-bottom: 20px;
}
  
.icons {
    display: flex;
    justify-content: space-between;
}
  
.icon {
    width: 50px;
    height: 50px;
    margin: 0 10px;
    background-color: transparent;
    border-radius: 0;
}
  
.icon img {
    width: 100%;
    height: 100%;
}
  
  
@media (max-width: 768px) {
    .content {
      width: 90%;
      max-width: 400px;
    }
  
    img {
      max-width: 150px;
    }
  
    .icon {
      width: 40px;
      height: 40px;
    }
}
  