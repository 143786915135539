.project {
    position: relative;
    width: 600px; /* Adjust based on your design */
    height: 700px; /* Adjust based on your design */
    margin: 20px; /* Adjust based on your design */
    overflow: hidden;
    border-radius: 20px; /* Rounded corners as per your design */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
    border: solid black;
  }
  
  .project-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;
    border-radius: 0;
    border-bottom: 1px solid black;
  }

  .project-img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover; /* Use contain to ensure the image is fully visible */
    border-radius: 0;
  }

  .project-title-container .text-container {
    display: flex;
    justify-content: center;
    align-items: top;
    height: 260px;
    width: 100%;
  }
  
  .project-title-container .text-container p {
    margin: 20px;
    font-size: 1.25rem;
  }
  
  .project-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .show {
    opacity: 1;
  }
  
  .project-description {
    color: white;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .project-button {
    padding: 10px 20px;
    background-color: #fff;
    color: #000;
    text-decoration: none;
    border-radius: 5px;
    font-size: large;
  }
  
  .project:hover {
    transform: scale(1.03); /* Slight zoom on hover */
  }
  
  .project:hover .project-img {
    opacity: 0.3;
  }
  