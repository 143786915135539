.main-contacts-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .main-contacts-container main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #f0f0f0;
    padding: 2rem;
    margin-bottom: 2rem;
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: for depth */
    width: 100%; /* Adjust the width to take up more space */
    max-width: 600px; /* Optional: to prevent it from getting too wide on large screens */
  }
  
  .contact-icon {
    font-size: 32px;
    margin-right: 1rem;
    color: #666;
  }
  
  .contact-info {
    font-size: 1.75rem; /* Larger text size */
    color: #333;
    text-decoration: none; /* Removes underline */
  }
  
  .contact-info:hover {
    text-decoration: underline; /* Adds underline on hover, if desired */
  }
  
  /* Responsive adjustments for smaller screens */
  @media (max-width: 768px) {
    .contact-item {
      padding: 1.5rem; /* Slightly smaller padding on smaller screens */
      width: 90%; /* Take up more width on smaller screens */
    }
  
    .contact-icon {
      font-size: 28px; /* Slightly smaller icons on smaller screens */
    }
  
    .contact-info {
      font-size: 1rem; /* Slightly smaller text on smaller screens */
    }
  }
  