.download-button {
    display: inline-block;
    padding: 10px 20px;
    margin: 10px 0;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    text-align: center;
  }
  
  .download-button:hover {
    background-color: #45a049;
  }
  