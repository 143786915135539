.App {
  /* Vertical scroll layout */
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100vh;
}

.section-homepage, .section-projects, .section-cv, .section-contacts {
  /* Each section snaps into place */
  scroll-snap-align: start;
  height: 100vh; /* Adjust as per your layout */
  overflow: hidden;
}
